@tailwind base;
@tailwind components;
@tailwind utilities;


#root {
    @apply min-h-screen;
}

.svg-loader{
    display:flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.svg-container {
    @apply mr-1;
}

.loader-svg{
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    fill: none;
    stroke-width: 5px;
    stroke-linecap: round;
    stroke: rgba(255, 255, 255, 1);
}
.loader-svg.bg{
    stroke-width: 8px;
    stroke: rgba(255, 255, 255, 0.3);
}

.animate{
    stroke-dasharray: 242.6;
    animation: fill-animation 1s cubic-bezier(1,1,1,1) 0s infinite;
}

@keyframes fill-animation{
    0%{
        stroke-dasharray: 40 242.6;
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dasharray: 141.3;
        stroke-dashoffset: 141.3;
    }
    100%{
        stroke-dasharray: 40 242.6;
        stroke-dashoffset: 282.6;
    }
}
